import * as React from "react";
import type { SVGProps } from "react";
const SvgInstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="currentcolor"
      d="M9 2.373c2.045 0 2.287.008 3.095.047.746.036 1.152.168 1.421.278.358.147.613.322.881.605s.434.552.573.93c.105.284.23.712.263 1.5.037.853.045 1.108.045 3.267s-.008 2.414-.045 3.266c-.034.788-.158 1.216-.263 1.501a2.5 2.5 0 0 1-.573.93 2.4 2.4 0 0 1-.88.604c-.27.111-.676.242-1.422.278-.808.04-1.05.047-3.095.047s-2.287-.008-3.094-.046c-.747-.037-1.153-.168-1.422-.279a2.4 2.4 0 0 1-.881-.604 2.5 2.5 0 0 1-.573-.93c-.105-.285-.23-.713-.263-1.5-.037-.853-.045-1.109-.045-3.267s.008-2.414.045-3.267c.034-.788.158-1.216.263-1.5.14-.378.305-.647.573-.93s.523-.458.88-.605c.27-.11.676-.242 1.423-.278.807-.039 1.05-.047 3.094-.047M9 .917c-2.08 0-2.34.01-3.157.048-.816.04-1.372.176-1.86.376a3.74 3.74 0 0 0-1.356.933c-.425.449-.687.9-.883 1.431-.19.515-.319 1.102-.356 1.962-.037.863-.046 1.138-.046 3.333s.01 2.47.046 3.333c.037.86.167 1.448.356 1.962.196.532.458.982.883 1.432.426.449.853.725 1.357.932.487.2 1.044.337 1.859.376.816.039 1.077.048 3.157.048s2.34-.009 3.157-.048c.816-.04 1.372-.176 1.86-.376a3.74 3.74 0 0 0 1.355-.932c.426-.45.688-.9.884-1.432.19-.514.319-1.102.356-1.962.037-.862.046-1.138.046-3.333s-.01-2.47-.046-3.333c-.037-.86-.167-1.448-.356-1.962a4 4 0 0 0-.884-1.431 3.74 3.74 0 0 0-1.356-.933c-.487-.2-1.044-.336-1.859-.376C11.341.926 11.08.917 9 .917m0 3.932C6.828 4.85 5.068 6.708 5.068 9S6.828 13.151 9 13.151s3.932-1.858 3.932-4.15S11.172 4.848 9 4.848m0 6.846c-1.41 0-2.553-1.206-2.553-2.695S7.59 6.306 9 6.306 11.553 7.512 11.553 9 10.41 11.695 9 11.695m4.088-7.98c-.508 0-.92.435-.92.97s.412.97.92.97.919-.434.919-.97-.412-.97-.92-.97"
    />
  </svg>
);
export default SvgInstagramIcon;
