import * as React from "react";
import type { SVGProps } from "react";
const SvgLineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="currentcolor"
      d="M17.5 7.814C17.5 4.057 13.687 1 9 1S.5 4.057.5 7.814c0 3.368 3.024 6.188 7.109 6.722.276.059.653.18.748.414.087.212.056.545.028.76 0 0-.1.592-.12.718-.038.212-.171.83.735.452.908-.377 4.895-2.847 6.678-4.874C16.91 10.67 17.5 9.318 17.5 7.814M6 9.823c0 .089-.072.16-.162.16H3.451a.16.16 0 0 1-.163-.16V6.159c0-.09.073-.16.163-.16h.603c.089 0 .162.072.162.16v2.908H5.84c.09 0 .163.073.163.16v.597zm1.438 0c0 .088-.072.16-.162.16h-.604a.16.16 0 0 1-.162-.16V6.159c0-.088.072-.16.162-.16h.604c.09 0 .162.07.162.16zm4.104 0c0 .088-.073.16-.163.16h-.599a.2.2 0 0 1-.042-.005h-.002l-.012-.003-.004-.002q-.005 0-.008-.003l-.008-.004-.005-.002-.01-.007-.002-.001a.2.2 0 0 1-.042-.04l-1.7-2.268v2.176c0 .089-.072.16-.162.16H8.18a.16.16 0 0 1-.163-.16V6.16c0-.088.073-.16.163-.16h.622l.007.001.01.003.005.001.01.003.006.002.01.004.004.002.009.005.005.003.008.006q.003 0 .005.003l.009.007.003.002.01.01v.001l.013.017 1.697 2.264V6.158c0-.088.073-.16.163-.16h.603c.09 0 .163.07.163.16v3.664Zm3.292-3.07c0 .09-.073.161-.163.161h-1.623v.619h1.623c.09 0 .163.072.163.16v.596c0 .089-.073.16-.163.16h-1.623v.619h1.623c.09 0 .163.072.163.16v.596c0 .09-.073.16-.163.16h-2.387a.16.16 0 0 1-.163-.16V6.16c0-.089.073-.16.163-.16h2.387c.09 0 .163.072.163.16v.596z"
    />
  </svg>
);
export default SvgLineIcon;
