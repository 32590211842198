import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { pageMaxWidth } from '../styles/page-max-width'
import SvgLogo from '../generated/svg/logo'
import SvgInstagramIcon from '../generated/svg/instagram-icon'
import SvgXIcon from '../generated/svg/x-icon'
import SvgYoutubeIcon from '../generated/svg/youtube-icon'
import SvgLineIcon from '../generated/svg/line-icon'
import dayjs from 'dayjs'
import classNames from 'classnames'
import SvgMinus from '../generated/svg/minus'
import SvgPlus from '../generated/svg/plus'
import { Mbr } from './br'
import { productSku } from '../product-sku'
import { skuToUrl } from '../utils/sku-to-url'

const Outer = styled.div`
  padding-top: 10rem;
  padding-bottom: 1rem;
`

const Inner = styled.div`
  ${pageMaxWidth}
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: min-content 1fr auto;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    grid-template-rows: none;
  }
`

const LogoContainer = styled.a`
  display: block;
  grid-row: 1;
  grid-column: 1;
  height: min-content;
  svg {
    display: block;
    height: 3.75rem;
    width: auto;
    order: -3;
  }
`

const SnsItemContainer = styled.div`
  display: flex;
  gap: 1rem;
  grid-row: 2;
  grid-column: 1;
  margin-top: 1.5rem;
  @media (max-width: 40rem){
    grid-area: auto;
    order: -1;
    justify-content: center;
  }
`

const SnsItem = styled.a`
  display: block;
  svg {
    display: block;
    /* height: 1.125rem; */
    height: 2rem;
    width: auto;
  }
`

const NavigatorContainer = styled.div`
  grid-row: 1 / span 2;
  grid-column: 2;
  display: flex;
  gap: 8rem;
  @media (max-width: 40rem){
    grid-area: auto;
    flex-direction: column;
    order: -2;
    gap: 0;
    margin-top: 3rem;
  }
`


const BottomLinkContainer = styled.div`
  grid-row: 3;
  grid-column: 2;
  margin-top: 3rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  @media (max-width: 40rem){
    grid-area: auto;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
`



const BottomLinkItem = styled.a`
  font-size: 0.6875rem;
  color: var(--sub-text-color);
  padding: 0.5rem;
  @media (max-width: 40rem){
    display: block;
  }
`

const Copyright = styled.div`
  font-size: 0.6875rem;
  color: var(--sub-text-color);
  grid-row: 3;
  grid-column: 1;
  margin-top: 3rem;
  @media (max-width: 40rem){
    grid-area: auto;
    order: 100;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`




type FooterProps = {

}
export const Footer: FunctionComponent<FooterProps> = () => {
  return <Outer>
    <Inner>
      <LogoContainer href="/shop">
        <SvgLogo />
      </LogoContainer>
      <SnsItemContainer>
        <SnsItem href="https://www.instagram.com/sknremed_official/" target='_blank'>
          <SvgInstagramIcon />
        </SnsItem>
        {/*
          <SnsItem href="https://twitter.com" target='_blank'>
            <SvgXIcon />
          </SnsItem>
          <SnsItem href="https://youtube.com" target='_blank'>
            <SvgYoutubeIcon />
          </SnsItem>
        */}
        <SnsItem href="https://lin.ee/mC20oFb" target='_blank'>
          <SvgLineIcon />
        </SnsItem>
      </SnsItemContainer>
      <NavigatorContainer>
        <Navigator
          label="PRODUCTS"
          items={[
            {
              label: 'ピールケアセラム(1.0/2.0/3.0)',
              link: skuToUrl(productSku.peelCare10.single)
            },
            {
              label: 'レチノダーマクリーム(0.1/0.2/0.4)',
              link: skuToUrl(productSku.retinoDermo01.single)
            },
            {
              label: 'ハイドロライトクリーム(2.5/4.0)',
              link: skuToUrl(productSku.hydroLight25.single)
            },
            {
              label: 'ナイアシナグロージェル',
              link: skuToUrl(productSku.niacinaGlow.single)
            },
            {
              label: 'スキンサイクルメソッド3点セット',
              link: skuToUrl(productSku.skinCycleMethod3PieceSet.single)
            },
            // {
            //   label: 'フリーセレクト',
            //   link: skuToUrl(productSku.freeSelect.single)
            // }
          ]}
        />
        <Navigator
          label="GUIDE"
          items={[
            {
              label: 'FAQ',
              link: '/shop/faq'
            },
            {
              label: 'NEWS',
              link: '/shop/information_categories/news'
            },
            {
              label: 'TOPICS',
              link: '/shop/information_categories/topics'
            }

          ]}
        />
        <Navigator
          label="CONTACT"
          items={[
            {
              label: 'お問い合わせ',
              link: '/shop/contact'
            }
          ]}
        />
      </NavigatorContainer>
      <Copyright>@ {dayjs().get('year')} SKN REMED</Copyright>
      <BottomLinkContainer>
        <BottomLinkItem href="/shop/base_info">会社概要</BottomLinkItem>
        <BottomLinkItem href="/shop/customer_term">利用規約</BottomLinkItem><Mbr />
        <BottomLinkItem href="/shop/law_info">特定商取引法に基づく表記</BottomLinkItem>
        <BottomLinkItem href="https://endeavour-co.jp/agreement/">プライバシーポリシー</BottomLinkItem>
      </BottomLinkContainer>
    </Inner>
  </Outer>
}

const NavigatorOuter = styled.div``

const NavigatorLabel = styled.div`
  font-size: 0.9375rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 40rem){
    cursor: pointer;
    padding: 0.5rem 0;
  }
  svg {
    display: none;
    @media (max-width: 40rem){
      display: block;
      height: 0.75rem;
      width: auto;
    }
  }
`

const NavigatorItemContainerWrapper = styled.div`
  display: contents;
  @media (max-width: 40rem){
    display: grid;
    transition: grid-template-rows 0.4s;
    grid-template-rows: 0fr;
    ${NavigatorOuter}.open & {
      grid-template-rows: 1fr;
    }
  }
`

const NavigatorItemContainer = styled.div`
  margin-top: 2rem;
  @media (max-width: 40rem){
    margin-top: 0;
    padding-top: 0.5rem;
    overflow: hidden;
  }
`

const NavigatorItem = styled.a`
  display: block;
  font-family: var(--en-font), var(--main-font);
  font-size: 0.75rem;
  line-height: 2.5;
`
type NavigatorProps = {
  label: string,
  items: {
    label: string,
    link: string
  }[]
}
const Navigator: FunctionComponent<NavigatorProps> = ({label, items}) => {
  const [open, setOpen] = useState(false)
  return <NavigatorOuter className={classNames({open})}>
    <NavigatorLabel onClick={() => setOpen(v => !v)}>
      {label}
      {open ? <SvgMinus /> : <SvgPlus />}
    </NavigatorLabel>
    <NavigatorItemContainerWrapper>
      <NavigatorItemContainer>
        {
          items.map((item, i) => {
            return<NavigatorItem key={i} href={item.link}>{item.label}</NavigatorItem>
          })
        }
      </NavigatorItemContainer>
    </NavigatorItemContainerWrapper>
  </NavigatorOuter>
}